import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { AARHUSCVR, AARHUSPHONE, CVR, ODENSEPHONE } from './CVR';

const Footer = () => (
  <footer className="bg-white content-info px-6 md:px-16 py-2 md:pb-6">
    <div className="flex flex-col md:flex-row my-16">
      <div className="flex flex-col pr-8 max-w-sm mb-8 md:mb-0">
        <p className="mb-2">Har du spørgsmål? Kontakt os her</p>
        <div className="font-semibold text-sm">
          <div className="mb-2">
            <a
              className="mb-1 transition duration-150 opacity hover:opacity-50"
              href="mailto:mrphanhouse@gmail.com"
            >
              mrphanhouse@gmail.com
            </a>
          </div>

          <div className="mb-2">
            <div>Odense</div>
            <div className="font-normal">
              <ODENSEPHONE />
              <CVR />
            </div>
          </div>

          <div className="">
            <div>Aarhus</div>
            <div className="font-normal">
              <AARHUSPHONE />
              <AARHUSCVR />
            </div>
          </div>
        </div>
      </div>
      <div className="w-full md:w-3/12 md:px-8 mb-8 md:mb-0">
        <ul>
          <li className="font-semibold mb-2">Menu</li>
          <li className="mb-1">
            <Link to="/">Forside</Link>
          </li>
          <li className="mb-1">
            <Link to="/mr-phan-house">Mr. Phan House</Link>
          </li>
          <li className="mb-1">
            <Link to="/vorfor-vaffel">Vorfor Vafler & Bakery</Link>
          </li>
        </ul>
      </div>
    </div>

    <div className="flex flex-col md:flex-row space-between text-gray-700">
      <div className="w-9/12">
        <p>
          <span className="text-xs">
            Copyright © {new Date().getFullYear()}, Mr. Phan
          </span>
        </p>
      </div>
      <div className="md:w-3/12 text-sm md:text-right">
        <p>
          <span>Site by </span>
          <a target="_blank" href="#">
            <span>
              <a
                href="https://www.instagram.com/brinckerphotography/"
                target="_blank"
                rel="noopener noreferrer"
                className="bold"
              >
                Becks &amp; Tony Studio
              </a>
            </span>
          </a>
        </p>
      </div>
    </div>
  </footer>
);

Footer.propTypes = {
  siteTitle: PropTypes.string,
};

Footer.defaultProps = {
  siteTitle: ``,
};

export default Footer;
