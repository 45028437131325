import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { gsap } from 'gsap';

import Header from './Header';
import Navigation from './Navigation';
import Footer from './Footer';

import 'styles/main.css';

function debounce(fn, ms) {
  let timer;
  return () => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      fn.apply(this, arguments);
    }, ms);
  };
}

const TemplateWrapper = ({ children }) => {
  const [dimensions, setDimensions] = useState({
    height: typeof window !== 'undefined' ? window.innerHeight : 0,
    width: typeof window !== 'undefined' ? window.innerWidth : 0,
  });

  useEffect(() => {
    // prevents flashing
    gsap.to('body', 0, { css: { visibility: 'visible' } });
    const debouncedHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }, 1000);

    window.addEventListener('resize', debouncedHandleResize);
    return () => {
      window.removeEventListener('resize', debouncedHandleResize);
    };
  });

  return (
    <>
      <Helmet title="Home | Gatsby + WordPress" />
      <Header dimensions={dimensions} />
      <div className="app relative z-20">
        <main className="bg-white">
          <div className="relative h-hero pt-32">{children}</div>
          <Footer />
        </main>
      </div>
      <Navigation />
    </>
  );
};

export default TemplateWrapper;
