import React from 'react';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import PropTypes from 'prop-types';

import Arrow from 'assets/svg/arrow-down.svg';
import { openMenu, closeMenu } from '../animations/menuAnimations';

const Header = ({ siteTitle, dimensions }) => {
  return (
    <header className="bg-white md:bg-transparent fixed z-30 w-full md:py-2">
      <div className="flex items-center justify-between w-full px-6 md:px-16 py-6 md:py-8">
        <div className="relative flex flex-wrap items-center justify-between md:py-4">
          <div className="relative z-10 flex-shrink-0 md:p-0">
            <AniLink
              cover
              direction="right"
              bg="#020202"
              to="/"
              className="font-semibold uppercase mb-4"
            >
              Phans Food
            </AniLink>
          </div>
        </div>

        <div className="relative text-sm">
          <div
            className="hb-menu cursor-pointer"
            onClick={() => openMenu(dimensions.width)}
          >
            <span></span>
            <span></span>
            <span></span>
          </div>

          <div
            className="hb-menu-close cursor-pointer text-lg"
            onClick={() => closeMenu()}
          >
            <Arrow className="w-8 ml-auto stroke-current transition-all duration-300 ease-in-out text-gray-500 hover:text-gray-900 transform -rotate-180" />
          </div>
        </div>
      </div>
    </header>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
