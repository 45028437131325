import React from 'react';

export const CVR = ({ className }) => {
  return <div className={className}>cvr: 43907123</div>;
};

export const AARHUSCVR = ({ className }) => {
  return <div className={className}>cvr: 41190426</div>;
};

export const AARHUSPHONE = ({ className }) => {
  return (
    <a
      className="mb-1 transition duration-150 opacity hover:opacity-50"
      href="tel:40284623"
    >
      +45 40 28 46 23
    </a>
  );
};

export const ODENSEPHONE = ({ className }) => {
  return (
    <a
      className="mb-1 transition duration-150 opacity hover:opacity-50"
      href="tel:26563581"
    >
      +45 26 56 35 81
    </a>
  );
};
