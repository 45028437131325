import gsap from 'gsap';

let tl = gsap.timeline();

export const openMenu = (width) => {
  tl.to('body', 0, { css: { overflow: 'hidden' } })
    .to('.app', 1, {
      // y: width <= 654 ? '70vh' : window.innerHeight / 2,
      y: '50vh',
      ease: 'expo.inOut',
    })
    .to('.nav', 1, {
      delay: -1,
      y: 0,
      ease: 'expo.inOut',
    })
    .to('.hb-menu span', 0.6, {
      delay: -1,
      scaleX: 0,
      transformOrigin: '50% 0%',
      ease: 'expo.inOut',
    })
    .to('.hb-menu-close', 0.6, {
      delay: -0.4,
      css: { display: 'block' },
    });
};

export const closeMenu = () => {
  tl.to('.app', 1, {
    y: 0,
    ease: 'expo.inOut',
  })
    .to('.nav', 1, {
      delay: -1,
      y: '-100%',
      ease: 'expo.inOut',
    })
    .to('.hb-menu span', 0.6, {
      delay: -0.6,
      scaleX: 1,
      transformOrigin: '50% 0%',
      ease: 'expo.inOut',
    })
    .to('.hb-menu-close', 0, {
      delay: -0.6,
      css: { display: 'none' },
    })
    .to('body', 0, { css: { overflow: 'auto' } });
};
