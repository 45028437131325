import React from 'react';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import gsap from 'gsap';
import { CVR } from './CVR';

let tl = gsap.timeline();

const Navigation = () => {
  return (
    <nav
      className="nav fixed bg-white w-full h-[70vh] md:h-screen-1/2 top-0 overflow-hidden z-20 transform -translate-y-full"
      // style={{ transform: 'translateY(-100%)' }}
    >
      <div className="absolute top-0 flex items-center h-full w-full">
        <div className="flex flex-col md:flex-row w-full">
          <div className="flex md:ml-10 md:items-baseline md:justify-between md:bg-transparent mb-8">
            <div className="flex flex-col">
              <AniLink
                cover
                direction="right"
                bg="#d2401e"
                className="ml-6 text-3xl mb-4 font-medium text-gray-900 hover:text-gray-700 ease-in-out transform transition-transform hover:translate-x-1 duration-200"
                activeClassName=""
                to={`/mr-phan-house`}
                key={`/mr-phan-house`}
                trigger={async (pages) => {
                  // wait until we have access to both pages
                  const exit = await pages.exit;
                  const entry = await pages.entry;
                  // here we can access both pages

                  // You could measure the entry element here

                  // start exit animation based on measurements if you want
                  // wait for the entering page to become visible
                  await entry.visible;
                  // the entering page is visible here.
                  // if you want you can animate it now!
                  tl.to('body', 0, { css: { overflow: 'auto' } });
                }}
              >
                Mr. Phan House
              </AniLink>
              <AniLink
                cover
                direction="right"
                bg="#dc976d"
                className="ml-6 text-3xl mb-4 font-medium text-gray-900 hover:text-gray-700 ease-in-out transform transition-transform hover:translate-x-1 duration-200"
                activeClassName=""
                to={`/vorfor-vaffel`}
                key={`/vorfor-vaffel`}
                trigger={async (pages) => {
                  // wait until we have access to both pages
                  const exit = await pages.exit;
                  const entry = await pages.entry;
                  // here we can access both pages

                  // You could measure the entry element here

                  // start exit animation based on measurements if you want
                  // wait for the entering page to become visible
                  await entry.visible;
                  // the entering page is visible here.
                  // if you want you can animate it now!
                  tl.to('body', 0, { css: { overflow: 'auto' } });
                }}
              >
                Vorfor vaffel
              </AniLink>

              <AniLink
                cover
                direction="right"
                bg="#2d3748"
                className="ml-6 text-3xl mb-4 font-medium text-gray-900 hover:text-gray-700 ease-in-out transform transition-transform hover:translate-x-1 duration-200"
                activeClassName=""
                to={`/take-away`}
                key={`/take-away`}
                trigger={async (pages) => {
                  // wait until we have access to both pages
                  const exit = await pages.exit;
                  const entry = await pages.entry;
                  // here we can access both pages

                  // You could measure the entry element here

                  // start exit animation based on measurements if you want
                  // wait for the entering page to become visible
                  await entry.visible;
                  // the entering page is visible here.
                  // if you want you can animate it now!
                  tl.to('body', 0, { css: { overflow: 'auto' } });
                }}
              >
                Take Away
              </AniLink>
            </div>
          </div>

          <div className="flex md:ml-auto px-6 md:px-16 font-semibold">
            <div>
              <a
                className="block mb-1 transition duration-150 opacity hover:opacity-50"
                href="mailto:mrphanhouse@gmail.com"
              >
                mrphanhouse@gmail.com
              </a>
              <a
                className="block mb-1 transition duration-150 opacity hover:opacity-50"
                href="tel:26563581"
              >
                +45 26 56 35 81
              </a>
              <CVR />
            </div>
          </div>
        </div>
      </div>
      {/* Off-canvas menu */}
    </nav>
  );
};

export default Navigation;
